<template>
	<div class="qstn-form-field qstn-form-field-2-2">
        <div class="qstn-form-field-title">{{data.title}}</div>
        <div class="qstn-form-input">
            <div class="qstn-form-input-status" :class="setStatus()"></div>
            <select class="qstn-form-value " v-model="data.value" @change="validate()">
                <option disabled selected value="null">Choose an option</option>
                <option v-for="option in data.options" :value="option">
                	{{option}}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		props: ['qstn','data'],
		methods: {
			validate() {
				if(this.data.value) {
		    		this.data.completed = true;
		    	}
				else {
					this.data.completed = false;
				}
				this.checkQuestions();
			},
			setStatus() {
				switch(this.data.completed) {
					case true:
						return 'complete';
					break;
					case false:
						return 'error';
					break;
					case null:
						return '';
					break;
				}
			},
			checkQuestions() {
				let completed, questions;
				completed = 0;
				questions = this.qstn.sections[this.qstn.pagination.current].questions.length;
				this.qstn.sections[this.qstn.pagination.current].questions.map((question) => {
					if(question.completed) {
						completed += 1;
					}
				});
				this.qstn.sections[this.qstn.pagination.current].completed = false;
				if(completed == questions) {
					this.qstn.sections[this.qstn.pagination.current].completed = true;
				}
			}
		},
		created() {

		},
		mounted() {
			
		}
	}
</script>