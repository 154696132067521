// import home from "./pages/home.vue";
// import about from "./pages/about.vue";
// import contact from "./pages/contact.vue";
// import team from "./pages/team.vue";
// import services from "./pages/services.vue";
// import pricing from "./pages/pricing.vue";

// // athletrics
// import athletrics from "./pages/projects/athletrics";

// // modforage
// import modforage from "./pages/projects/modforage";

// // questn
// import questn from "./pages/projects/questn";

// // webscrpr
// import webscrpr from "./pages/projects/webscrpr";

// // educoded
// import educoded from "./pages/projects/educoded";

// // homeforage
// import homeforage from "./pages/projects/homeforage";

// // admin
// import admin_dashboard from "./pages/admin/dashboard.vue";

// import admin_forms_audits from "./pages/admin/forms/audits.vue";
// import admin_forms_contacts from "./pages/admin/forms/contacts.vue";

// // projects
// import admin_projects_modforage from "./pages/admin/projects/modforage/index.vue";

// // Auth
// import login from "./pages/auth/login.vue";
// import register from "./pages/auth/register.vue";

// export default {
// 	mode: 'history',
// 	hash: false,
// 	routes: [
// 	  { path: '/', component: home },
// 	  { path: '/about', component: about },
// 	  { path: '/contact', component: contact },
// 	  { path: '/team', component: team },
// 	  { path: '/services', component: services },
// 	  { path: '/plans', component: pricing },
// 	  { path: '/admin', component: admin_dashboard },
// 	  { path: '/admin/audits', component: admin_forms_audits },
// 	  { path: '/admin/contacts', component: admin_forms_contacts },
// 	  { path: '/admin/modforage', component: admin_projects_modforage },
// 	  { path: '/login', component: login },
// 	  { path: '/register', component: register },
// 	  { path: '/athletrics', component: athletrics },
// 	  { path: '/modforage', component: modforage },
// 	  { path: '/questn', component: questn },
// 	  { path: '/webscrpr', component: webscrpr },
// 	  { path: '/educoded', component: educoded },
// 	  { path: '/homeforage', component: homeforage },
// 	]
// }



import { createWebHistory, createRouter} from 'vue-router';
import axios from 'axios';
import { useStore } from "vuex";
import store from './store';

// frontend
import home from "./pages/home";
import about from "./pages/about";
import team from "./pages/team";
// import cars from "./pages/cars/index";
// import cars_view from "./pages/cars/view";
// import car_view from "./pages/car/view";

// dashboard
// import dashboard from "./pages/admin/dashboard";
// import admin_cars from "./pages/admin/cars";
// import admin_cars_view from "./pages/admin/cars/view";
// import admin_cars_create from "./pages/admin/cars/create";

// import admin_car_view from "./pages/admin/car/view";

// auth
// import login from "./pages/auth/login";
// import register from "./pages/auth/register";

// 404
// import notFound from "./pages/notfound";

const routes = [
    {
        path: '/',
        component: home,
        meta: {layout: 'frontend', slug: 'home'}
    },
    {
        path: '/about',
        component: about,
        meta: {layout: 'frontend', slug: 'about'}
    },
    {
        path: '/team',
        component: team,
        meta: {layout: 'frontend', slug: 'team'}
    },
    // {
    //     path: '/cars',
    //     component: cars,
    //     meta: {layout: 'frontend', slug: 'cars'}
    // },
    // {
    //     path: '/cars/:id',
    //     component: cars_view,
    //     meta: {layout: 'frontend', slug: 'cars_view'}
    // },
    // {
    //     path: '/car/:id',
    //     component: car_view,
    //     meta: {layout: 'frontend', slug: 'car'}
    // },
    // {
    //     path: '/admin',
    //     component: dashboard,
    //     meta: {layout: 'admin', slug: 'dashboard'}
    // },
    // {
    //     path: '/admin/cars',
    //     component: admin_cars,
    //     meta: {layout: 'admin', slug: 'admin_cars'}
    // },
    // {
    //     path: '/admin/cars/:id',
    //     component: admin_cars_view,
    //     meta: {layout: 'admin', slug: 'admin_cars_view'}
    // },
    // {
    //     path: '/admin/cars/create',
    //     component: admin_cars_create,
    //     meta: {layout: 'admin', slug: 'admin_cars_create'}
    // },
    // {
    //     path: '/admin/car/:id',
    //     component: admin_car_view,
    //     meta: {layout: 'admin', slug: 'admin_car_view'}
    // },
    // {
    //     path: '/login',
    //     component: login,
    //     meta: {layout: 'frontend', slug: 'login'}
    // },
    // {
    //     path: '/register',
    //     component: register,
    //     meta: {layout: 'frontend', slug: 'register'}
    // },
    // {
    //     path: '/admin/:catchAll(.*)',
    //     component: notFound,
    //     meta: {layout: 'admin', slug: '404'}
    // },
    // {
    //     path: '/:catchAll(.*)',
    //     component: notFound,
    //     meta: {layout: 'frontend', slug: '404'}
    // }
]

const router = createRouter({
    history: createWebHistory(),
    hash: false,
    routes
});

router.beforeEach((to, from) => {
    store.dispatch("layout/setPageType", to.meta.slug);
    store.dispatch("layout/setLayout", to.meta.layout);
});

export default router