import axios from "axios";

const state = () => ({
    id: '',
    name: '',
    email: ''
});

// getters
const getters = {
    getUser(state) {
        return state;
    }
}

// actions
const actions = {
    setUser({ commit }, user) {
        commit('setUser', user)
    },
    getUser({ commit }) {
        axios.get('/api/get-user')
        .then(res => {
            if(res.data) {
                commit('setUser', res.data);
            }
        });
    }
}

// mutations
const mutations = {
    setUser(state, user) {
        Object.assign(state, user)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}