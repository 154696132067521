<template>
	<div class="tmg-wrapper" v-if="sldr.items">
		<div class="sldr-item" :class="setSizes()" v-for="(sld, index) in sldr.active_items">
	    	<div class="sld-container">
	            <div class="tmg-stories-card-image-container">
	            	<a :href="sld.link" target="_blank">
	                    <img v-lazy="sld.image" width="100%" />
	                </a>
	            </div>
	            <div class="sld-item-title">
	            	<a v-html="sld.title" :href="sld.link" target="_blank"></a>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		props: ['sldr','pagination'],
		methods: {
			setSizes() {
                let size, name;
                size = parseInt(100/this.sldr.options.items);
                name = 'tmg-'+this.sldr.options.size+'-'+size;
                return name;
            }
		},
		created() {
			
		},
		mounted() {

		}
	}
</script>