<template>
	<div class="tmg-header tmg-15" :class="{'active' : layout.scrolled.position > 65}">
		<div class="tmg-header-main tmg-wrapper">
			<div class="tmg-header-logo">
				<router-link to="/" exact>
					<div class="tmg-header-logo-container tmg-wrapper">
						<div class="tmg-logo tmg-wrapper">
							<img src="https://maione-group.s3.us-west-2.amazonaws.com/m-logo-small.png" alt="maione group logo" width="55px" height="55px">
						</div>
					</div>
					<div class="tmg-header-logo-text tmg-wrapper tmg-25">
						Maione Group
					</div>
				</router-link>
			</div>
			<div class="tmg-header-menu tmg-wrapper">
				<div class="tmg-header-menu-container">
					<!-- <div class="tmg-header-menu-list tmg-wrapper">
						<div class="tmg-header-menu-item">
							<router-link to="/services">services</router-link>
						</div>
						<div class="tmg-header-menu-item">
							<router-link to="/about">about</router-link>
						</div>
						<div class="tmg-header-menu-item">
							<router-link to="/plans">plans</router-link>
						</div>
						<div class="tmg-header-menu-item">
							<a target="_blank" href="https://blog.maionegroup.com">blog</a>
						</div>
						<div class="tmg-header-menu-item">
							<router-link to="/contact">contact</router-link>
						</div>
					</div> -->
				</div>
			</div>
			<div class="tmg-header-sidebar">
				<div class="tmg-header-sidebar-container tmg-wrapper">
					<div class="tmg-header-sidebar-lines tmg-wrapper" @click="openSidebar">
					    <div class="tmg-header-sidebar-line"></div>
					    <div class="tmg-header-sidebar-line"></div>
					    <div class="tmg-header-sidebar-line"></div>
					</div>
				</div>
			</div>
			<account :user="user" :layout="layout"></account>
        </div>
    </div>
</template>

<script>

    import account from "./admin/layout/account.vue";

    export default {
        components: {
            account
        },
		data() {
			return {

			}
		},
		props: ['user','layout'],
		methods: {
			openSidebar() {
				this.layout.profile.open = true;
			}
		},
		created() {

		},
		mounted() {
			
		},
		computed: {
        	layout() {
        		return this.$store.state.layout ?? {};
        	}
        }
	}

</script>

<style>
	.tmg-header {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 90px;
		background: #e7e7e7;
	}
</style>