import axios from "axios";

const state = () => ({
    header: "", 
    footer: "", 
    menu: {
        list: [
            {
                name: 'dashboard',
                icon: 'M20 40v-12h8v12h10v-16h6l-20-18-20 18h6v16z',
                link: '/admin',
                exclude: []
            },
            {
                name: 'members',
                icon: 'M24 8c-4.42 0-8 3.58-8 8 0 4.41 3.58 8 8 8s8-3.59 8-8c0-4.42-3.58-8-8-8zm0 20c-5.33 0-16 2.67-16 8v4h32v-4c0-5.33-10.67-8-16-8z',
                link: '',
                children: {
                    active: false,
                    list: [
                        {
                            name: 'view all',
                            icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18H18v-4h20v4zm-8 8H18v-4h12v4zm8-16H18v-4h20v4z',
                            link: '/admin/members',
                        },
                        {
                            name: 'add member',
                            icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z',
                            link: '/admin/members/add',
                        }
                    ]
                },
                exclude: [111]
            },
            {
                name: 'car groups',
                icon: 'M24 14v-8h-20v36h40v-28h-20zm-12 24h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm8 24h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4v-4h4v4zm20 24h-16v-4h4v-4h-4v-4h4v-4h-4v-4h16v20zm-4-16h-4v4h4v-4zm0 8h-4v4h4v-4z',
                link: '',
                children: {
                    active: false,
                    list: [
                        {
                            name: 'view all',
                            icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18H18v-4h20v4zm-8 8H18v-4h12v4zm8-16H18v-4h20v4z',
                            link: '/admin/cars',
                        },
                        {
                            name: 'add car group',
                            icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z',
                            link: '/admin/cars/create',
                        }
                    ]
                },
                exclude: [111]
            },
            {
                name: 'contracts',
                icon: 'M28 4h-16c-2.21 0-3.98 1.79-3.98 4l-.02 32c0 2.21 1.77 4 3.98 4h24.02c2.21 0 4-1.79 4-4v-24l-12-12zm4 32h-16v-4h16v4zm0-8h-16v-4h16v4zm-6-10v-11l11 11h-11z',
                link: '',
                children: {
                    active: false,
                    list: [
                        {
                            name: 'view all',
                            icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18H18v-4h20v4zm-8 8H18v-4h12v4zm8-16H18v-4h20v4z',
                            link: '/admin/contracts',
                        },
                        {
                            name: 'add contract',
                            icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z',
                            link: '/admin/contracts/add',
                        }
                    ]
                },
                exclude: [111]
            },
            {
                name: 'schedules',
                icon: 'M34 24h-10v10h10v-10zm-2-22v4h-16v-4h-4v4h-2c-2.21 0-3.98 1.79-3.98 4l-.02 28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4h-2v-4h-4zm6 36h-28v-22h28v22z',
                link: '/admin/schedules',
                exclude: [111]
            },
            {
                name: 'finance',
                icon: 'M23.6 21.8c-4.54-1.18-6-2.39-6-4.29 0-2.18 2.01-3.71 5.4-3.71 3.56 0 4.88 1.7 5 4.2h4.42c-.13-3.45-2.24-6.59-6.42-7.62v-4.38h-6v4.32c-3.88.85-7 3.35-7 7.22 0 4.62 3.83 6.92 9.4 8.26 5.01 1.2 6 2.95 6 4.83 0 1.37-.97 3.57-5.4 3.57-4.12 0-5.75-1.85-5.96-4.2h-4.41c.25 4.38 3.52 6.83 7.37 7.66v4.34h6v-4.3c3.89-.75 7-3 7-7.11 0-5.66-4.86-7.6-9.4-8.79z',
                link: '',
                children: {
                    active: false,
                    list: [
                        {
                            name: 'bids',
                            icon: '',
                            link: '',
                            children: {
                                active: false,
                                list: [
                                    {
                                        name: 'view all',
                                        icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18H18v-4h20v4zm-8 8H18v-4h12v4zm8-16H18v-4h20v4z',
                                        link: '/admin/bids',
                                    },
                                    {
                                        name: 'add bid',
                                        icon: 'M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z',
                                        link: '/admin/bids/add',
                                    }
                                ]
                            }
                        },
                        {
                            name: 'costs',
                            icon: '',
                            link: '/admin/costs',
                        },
                        {
                            name: 'invoices',
                            icon: 'M36 34h-24v-4h24v4zm0-8h-24v-4h24v4zm0-8h-24v-4h24v4zm-30 26l3-3 3 3 3-3 3 3 3-3 3 3 3-3 3 3 3-3 3 3 3-3 3 3v-40l-3 3-3-3-3 3-3-3-3 3-3-3-3 3-3-3-3 3-3-3-3 3-3-3v40z',
                            link: '/admin/invoices',
                        },
                        {
                            name: 'payroll',
                            icon: '',
                            link: '/admin/payroll',
                        },
                    ]
                },
                exclude: [111]
            },
            {
                name: 'settings',
                icon: 'M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97l-4.98-2.01c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31c-.08.64-.14 1.29-.14 1.95s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zm-14.86 5.05c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
                link: '',
                children: {
                    active: false,
                    list: [
                        {
                            name: 'administrative',
                            icon: 'M6 34v4h12v-4h-12zm0-24v4h20v-4h-20zm20 32v-4h16v-4h-16v-4h-4v12h4zm-12-24v4h-8v4h8v4h4v-12h-4zm28 8v-4h-20v4h20zm-12-8h4v-4h8v-4h-8v-4h-4v12z',
                            link: '/admin/settings',
                        },
                        {
                            name: 'profile',
                            icon: 'M24 11.8c2.32 0 4.2 1.88 4.2 4.2s-1.88 4.2-4.2 4.2-4.2-1.88-4.2-4.2 1.88-4.2 4.2-4.2m0 18c5.95 0 12.2 2.91 12.2 4.2v2.2h-24.4v-2.2c0-1.29 6.25-4.2 12.2-4.2m0-21.8c-4.42 0-8 3.58-8 8 0 4.41 3.58 8 8 8s8-3.59 8-8c0-4.42-3.58-8-8-8zm0 18c-5.33 0-16 2.67-16 8v6h32v-6c0-5.33-10.67-8-16-8z',
                            link: '/admin/profile',
                        }
                    ]
                },
                exclude: [111,999]
            }
        ],
        open: false
    },
    sidebar: {
        type: "",
        open: false, 
        data: {},
        active: ""
    }, 
    profile: {
        open: false
    }, 
    map: {
        open: false
    },
    data: {},
    scrolled: {
        position: 0,
        direction: null
    },
    page_type: '',
    no_scroll: false
});

// getters
const getters = {
    // getLayout(state) {
    //     return state;
    // }
}

// actions
const actions = {
    // setSidebar({ commit }, data) {
    //     commit('setSidebar', data)
    // },
    toggleSidebar({ commit }, data) {
        commit('toggleSidebar', data);
    },
    setMap({ commit }, data) {
        commit('setMap', data)
    },
    setPageType({ commit }, page) {
        commit('setPageType', page)
    },
    setLayout({ commit }, layout) {
        commit('setLayout', layout)
    },
    setScrolled({ commit }, scroll) {
        commit('setScrolled', scroll)
    },
    noScroll({ commit }) {
        commit('noScroll');
    }
}

// mutations
const mutations = {
    // setSidebar(state, data) {
    //     state.sidebar = data;
    //     console.log(data);
    //     // if(state.sidebar.open) {
    //     //     this.commit('layout/noScroll');
    //     // }
    //     // else {
    //     //     this.commit('layout/noScroll');
    //     // }
    // },
    setMap(state, data) {
        state.map.open = data;
    },
    setPageType(state, value) {
        state.page_type = value;
    },
    setLayout(state, value) {
        state.header = value+'_header';
        state.footer = value+'_footer';
        setTimeout(() => {
            console.log('scroll up');
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 250);
    },
    setScrolled(state, data) {
        state.scrolled.position = data.position;
        state.scrolled.direction = data.direction;
    },
    toggleSidebar(state, data) {
        console.log(data);
        state.sidebar.open = !state.sidebar.open;
        state.sidebar.data = data.data;
        state.sidebar.type = data.type;
    },
    noScroll(state) {
        let app = document.getElementById("otto-app");
        if(state.noScroll) {
            app.classList.remove("noScroll");
            state.noScroll = false;
        }
        else {
            app.classList.add("noScroll");
            state.noScroll = true;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}