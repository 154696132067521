<template>
	<div class="qstn-form-field qstn-form-field-2-2">
        <div class="qstn-form-field-title">{{data.title}}</div>
        <div class="qstn-form-input">
            <div class="qstn-form-input-status" :class="setStatus()"></div>
            <textarea class="qstn-form-value " v-model="data.value" @input="validate()" :placeholder="data.placeholder"></textarea>
        </div>
    </div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		props: ['qstn','data'],
		methods: {
			validate() {
				if(this.data.value) {
		    		if(this.data.value.length >= this.data.attr.min && this.data.value.length <= this.data.attr.max) {
						this.data.completed = true;
					}
					else {
						this.data.completed = false;
					}
		    	}
				else {
					this.data.completed = false;
				}
				this.checkQuestions();
			},
			setStatus() {
				switch(this.data.completed) {
					case true:
						return 'complete';
					break;
					case false:
						return 'error';
					break;
					case null:
						return '';
					break;
				}
			},
			checkQuestions() {
				let completed, questions;
				completed = 0;
				questions = this.qstn.sections[this.qstn.pagination.current].questions.length;
				this.qstn.sections[this.qstn.pagination.current].questions.map((question) => {
					if(question.completed) {
						completed += 1;
					}
				});
				this.qstn.sections[this.qstn.pagination.current].completed = false;
				if(completed == questions) {
					this.qstn.sections[this.qstn.pagination.current].completed = true;
				}
			}
		},
		created() {

		},
		mounted() {
			
		}
	}
</script>