require('./bootstrap');

import { createApp } from 'vue';
import { useStore } from "vuex";
import axios from "axios";
import routes from './routes';
import store from './store';
import lodash from 'lodash';
import VueLazyLoad from 'vue3-lazyload';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp({
    data() {
        return {
            scrollPosition: 0,
            page: "",
            data: "",
            user: window.user,
            layout: {header: "frontend_header", scrolled: null, footer: "frontend_footer", sidebar: {open: false, active: ""}, profile: {open: false}, data: {}}
        }
    },
    provide: {
      _: lodash
    },
    methods: {
        handleScroll() {
            let currentScrollPosition = window.scrollY;
            if(currentScrollPosition < this.scrollPosition){
                store.dispatch("layout/setScrolled", {direction: 'up', position: currentScrollPosition});
            }
            else{
                store.dispatch("layout/setScrolled", {direction: 'down', position: currentScrollPosition});
            }
            this.scrollPosition = window.scrollY;
        }
    },
    computed: {
        layout() {
            return this.$store.state.layout ?? {};
        },
        user() {
            return this.$store.state.user ?? {};
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
        // const store = useStore();
        // store.dispatch("user/getUser");
    },
    destroyed() {
        window.removeEventListener("scroll",this.handleScroll);
    }
});

app.component('page-header', require('./layouts/header.vue').default);
app.component('page-footer', require('./layouts/footer.vue').default);
app.component('qstn', require('./components/qstn/view.vue').default);
app.component('sldr', require('./components/sldr/view.vue').default);
app.component('sldr-gallery', require('./components/sldr/templates/gallery.vue').default);
app.component('sldr-cards', require('./components/sldr/templates/cards.vue').default);

app.use(VueSweetalert2);
app.use(VueLazyLoad);
app.use(routes);
app.use(store);
app.mount('#app');



