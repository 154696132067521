<template>
    <div class="tmg-wrapper" v-if="sldr.items">
        <div class="sldr-item" :class="setSizes()" v-for="(sld, index) in sldr.active_items">
            <div class="tmg-services-card tmg-15">
                <div class="tmg-services-card-image-container">
                    <div class="tmg-services-card-image" :style="setImage(sld.image)" style="background-size: contain; background-position: center center; background-repeat: no-repeat;"></div>
                </div>
                <div class="tmg-services-card-content">
                    <div class="tmg-services-card-title">{{sld.title}}</div>
                    <div class="tmg-services-card-text" v-html="setContent(sld.content)"></div>
                    <div class="tmg-services-card-btn tmg-wrapper">learn more</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        props: ['sldr','pagination'],
        methods: {
            setImage(val) {
                // return 'background-image: url('+val+')';
                let degree = 0;
                degree = Math.floor(Math.random() * 360) + 1;
                return 'background: linear-gradient('+degree+'deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,1) 100%);"';
            },
            setLink(url) {
                return url;
            },
            setContent(val) {
                let content, length = 175;
                content = val.length > length ? val.substring(0, length - 3) + "..." : val;
                return content;
            },
            setSizes() {
                let size, name;
                size = parseInt(100/this.sldr.options.items);
                name = 'tmg-'+this.sldr.options.size+'-'+size;
                return name;
            }
        },
        created() {
            
        },
        mounted() {

        }
    }
</script>