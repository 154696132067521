<template>
	<div class="tmg-footer">
		<div class="tmg-footer-main">
			<div class="tmg-footer-content">
				<div class="tmg-container">
					<div class="tmg-footer-menu tmg-wrapper">
						<div class="tmg-xs-100 tmg-sm-50 tmg-md-25 tmg-lg-25">
							<div class="tmg-footer-menu-block-wrapper tmg-wrapper">
								<div class="tmg-footer-menu-block">
									<div class="tmg-footer-menu-title">About</div>
									<a href="/about" name="about us" aria-label="about us">
										<div class="tmg-footer-menu-item">About Us</div>
									</a>
									<a href="/team" name="team" aria-label="team">
										<div class="tmg-footer-menu-item">Team</div>
									</a>
									<a href="/" name="blog" aria-label="blog">
										<div class="tmg-footer-menu-item">Blog</div>
									</a>
								</div>
							</div>
						</div>
						<div class="tmg-xs-100 tmg-sm-50 tmg-md-25 tmg-lg-25">
							<div class="tmg-footer-menu-block-wrapper tmg-wrapper">
								<div class="tmg-footer-menu-block">
									<div class="tmg-footer-menu-title">Browse</div>
									<a href="/services" name="services" aria-label="services">
										<div class="tmg-footer-menu-item">Services</div>
									</a>
									<a href="/pricing" name="pricing" aria-label="pricing">
										<div class="tmg-footer-menu-item">Pricing</div>
									</a>
									<a href="/" name="portfolio" aria-label="portfolio">
										<div class="tmg-footer-menu-item">Portfolio</div>
									</a>
								</div>
							</div>
						</div>
						<div class="tmg-xs-100 tmg-sm-50 tmg-md-25 tmg-lg-25">
							<div class="tmg-footer-menu-block-wrapper tmg-wrapper">
								<div class="tmg-footer-menu-block">
									<div class="tmg-footer-menu-title">Services</div>
									<a href="/" name="web design & development" aria-label="web design & development">
										<div class="tmg-footer-menu-item">Web Design &amp; Devlelopment</div>
									</a>
									<a href="/" name="social media management" aria-label="social media management">
										<div class="tmg-footer-menu-item">Social Media Management</div>
									</a>
									<a href="/" name="email & sms marketing" aria-label="email & sms marketing">
										<div class="tmg-footer-menu-item">Email &amp; SMS Marketing</div>
									</a>
									<a href="/" name="reporting & analytics" aria-label="reporting & analytics">
										<div class="tmg-footer-menu-item">Reporting &amp; Analytics</div>
									</a>
									<a href="/" name="seo & sem" aria-label="seo & sem">
										<div class="tmg-footer-menu-item">SEO &amp; SEM</div>
									</a>
								</div>
							</div>
						</div>
						<div class="tmg-xs-100 tmg-sm-50 tmg-md-25 tmg-lg-25">
							<div class="tmg-footer-menu-block-wrapper tmg-wrapper">
								<div class="tmg-footer-menu-block">
									<div class="tmg-footer-menu-title">Other</div>
									<a href="/" name="account" aria-label="account">
										<div class="tmg-footer-menu-item">Account</div>
									</a>
									<a href="/register" name="register" aria-label="register">
										<div class="tmg-footer-menu-item">Register</div>
									</a>
									<a href="/contact" name="contact" aria-label="contact">
										<div class="tmg-footer-menu-item">Contact</div>
									</a>
									<a href="/" name="affiliate program" aria-label="affiliate program">
										<div class="tmg-footer-menu-item">Affiliate Program</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="tmg-footer-newsletter"></div>
					<div class="tmg-footer-social tmg-wrapper">
						<a href="/" target="_blank" class="tmg-footer-social-item tmg-wrapper" name="facebook" aria-label="facebook">
							<span class="fab fa-facebook"></span>
						</a>
						<a href="https://instagram.com/maione.group" target="_blank" class="tmg-footer-social-item tmg-wrapper" name="instagram" aria-label="instagram">
							<span class="fab fa-instagram"></span>
						</a>
						<a href="/" target="_blank" class="tmg-footer-social-item tmg-wrapper" name="youtube" aria-label="youtube">
							<span class="fab fa-youtube"></span>
						</a>
						<a href="/" target="_blank" class="tmg-footer-social-item tmg-wrapper" name="twitter" aria-label="twitter">
							<span class="fab fa-twitter"></span>
						</a>
						<a href="/" target="_blank" class="tmg-footer-social-item tmg-wrapper" name="tiktok" aria-label="tiktok">
							<span class="fab fa-tiktok"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="tmg-footer-copyright">
			<div class="tmg-wrapper" v-html="copyright()"></div>
		</div>
	</div>
</template>

<script>

	import moment from "moment";
	export default {
		data() {
			return {

			}
		},
		props: ['user'],
		methods: {
			copyright() {
				return '&copy; '+moment().format('Y') + ' Maione Group LLC';
			}
		},
		created() {

		},
		mounted() {

		}
	}

</script>