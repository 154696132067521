<template>
	<div class="tmg-page">
	    <div class="tmg-angled-sections">
	        <div class="tmg-section tmg-angled-section tmg-section-cover tmg-section-type-gradient tmg-section-layout-normal tmg-25 active" style="background: linear-gradient(-45deg, rgba(255,255,255,0.5), rgba(0,0,0,1));">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-hero-particle-js" id="particles-js"></div>
	            <div class="tmg-angled-section-wrapper tmg-container" style="margin-top: 50px;">
	                <div class="tmg-wrapper">
	                    <div class="tmg-xs-100 tmg-sm-50">
	                        <div class="tmg-page-content">
	                            <div class="tmg-page-cover-title"> About </div>
	                            <div class="tmg-page-cover-subtitle"> Who we are </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="tmg-section tmg-angled-section tmg-section-content tmg-section-type-normal tmg-section-layout-inverse tmg-25" style="background: #fff;">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-hero-particle-js" id="tmg-contact-particles-js"></div>
	            <div class="tmg-angled-section-wrapper tmg-container">
	                <div class="tmg-wrapper">
	                    <div class="tmg-xs-70 tmg-angled-section-content">
	                        <div class="tmg-page-content">
	                            <div class="tmg-page-text">A marketer and a developer walk into a coffee shop... what walked out was something bigger than either of us could have imagined. Through our common love for <span>coffee + creativity</span>, we discovered that despite having corporate experience - we were both missing something in our careers and lives: using our powers for good. In other words, <span>helping businesses truly grow.</span> For us, there's nothing greater than helping a local business or entrepreneur grow to see their dreams come true. Reach for the stars! Because in this day and age anything is possible. 
	                            	<br>
	                                <br>
	                                <router-link to="/team">
	                                    <div class="tmg-page-btn tmg-wrapper" style="background: #f9f9f9;">Meet the team!</div>
	                                </router-link>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				team: [

				]
			}
		},
		props: ['user'],
		methods: {

		},
		created() {

		},
		mounted() {

		}
	}

</script>