<template>
    <div class="tmg-sidebar tmg-25" :class="{'active' : layout.profile.open}">
        <div class="tmg-sidebar-container">
            <div class="tmg-sidebar-overview tmg-wrapper" v-if="getName">
                Hey, {{getName}}!
            </div>
            <div class="tmg-sidebar-sections">
                <div class="tmg-sidebar-section tmg-sidebar-section-profile active">
                    <div v-if="!getName">
                        <div class="tmg-sidebar-login-form">
                            <div class="tmg-sidebar-login-form-sections">
                                <div class="tmg-sidebar-login-form-section">
                                    <input type="email" v-model="credentials.email" placeholder="me@example.com">
                                </div>
                                <div class="tmg-sidebar-login-form-section">
                                    <input type="password" v-model="credentials.password" placeholder="••••••••">
                                </div>
                            </div>
                            <div class="tmg-sidebar-login-form-btn tmg-wrapper" @click="login()">login</div>
                        </div>
                    </div>
                    <div v-else>
                        <a href="/admin">
                            <div class="tmg-sidebar-login-form-btn tmg-wrapper" style="margin-bottom: 10px;">admin</div>
                        </a>
                        <div class="tmg-sidebar-login-form-btn tmg-wrapper" @click.prevent="logout()">logout</div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="tmg-sidebar-overlay" :class="{'active' : layout.profile.open}" @click="layout.profile.open = !layout.profile.open"></div>
        </transition>
    </div>
</template>

<script>
    
    import { mapState, mapActions } from 'vuex'
    export default {
        data() {
            return {
                credentials: {
                    email: "",
                    password: ""
                },
                userStatus: false
            }
        },
        props: ['user','layout'],
        methods: {
            ...mapActions({
                setUser: 'user/setUser'
            }),
            logout() {
                axios.post('/logout')
                .then((response) => {
                    window.location.href = '/';
                })
                .catch(error => {
                   console.log(error);
                });
            },
            login() {
                let base, credentials, email, password;
                base = this;
                credentials = this.credentials;
                email = credentials.email;
                password = credentials.password;

                axios.post('/api-login', {
                    'email': email,
                    'password': password
                })
                .then((response) => {
                    if(response.data != 'error') {
                        base.userStatus = true;
                        base.updateUser(response.data);
                        base.setUser({
                            ...response.data
                        });
                    }
                    else {
                        this.$swal.fire(
                            'Error',
                            'The email and password combination was incorrect.',
                            'error'
                        )
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            },
            updateUser(obj) {
                // this.user = obj;
                // Vue.set(this,'user',obj);
                this.$root.setData(obj);
            }
        },
        created() {
            
        },
        mounted() {

        },
        computed: {
            getName() {
                return this.$store.state.user.name;
            }
        },
        watch: {
            // userStatus() {
   //              let user;
   //              user = this.user;
   //              console.log('user is online: ', this.userStatus);
   //              this.$root.setData(user);
   //          }
        }
    }

</script>

<style>
    .tmg-sidebar-overview {
        width: 100%;
        height: 250px;
        background: #000;
        color: #fff;
    }
</style>