<template>
	<div class="qstn-form-field qstn-form-field-1-1">
        <div class="qstn-form-field-title">{{data.title}}</div>
        <div class="qstn-form-input">
            <div class="qstn-form-input-status" :class="setStatus()"></div>
            <input :type="data.type" class="qstn-form-value" :class="data.attr.set" v-model="data.value" :data-min="data.attr.min" :data-max="data.attr.max" :placeholder="data.placeholder" autocomplete="off" @input="validate()">
        </div>
    </div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		props: ['qstn', 'data'],
		methods: {
			validate() {
				if(this.data.value) {
					if(this.data.value.length >= this.data.attr.min && this.data.value.length <= this.data.attr.max) {
						this.data.completed = true;
					}
					else {
						this.data.completed = false;
					}

					switch (this.data.attr.set) {
						case 'email':
							this.validateEmail();
						break;
						case 'phone':
							this.validatePhone();
						break;
						case 'website':
							this.validateWebsite();
						break;
						case 'password':
							this.validatePassword();
						break;
						default:
							
						break;
					}
				}
				else {
					this.data.completed = null;
				}
				this.checkQuestions();
			},
			validateEmail() {
				let value, validator;
				value = this.data.value;
				validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		    	if(validator.test(String(value).toLowerCase())) {
		    		this.data.completed = true;
		    	}
				else {
					this.data.completed = false;
				}
			},
			validatePhone() {
				jQuery('.phone').mask('(000) 000-0000');
			},
			validatePassword() {
				let value, validator;
				value = this.data.value;
				validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
				if(validator.test(String(value)) ) {
		    		this.data.completed = true;
		    	}
				else {
					this.data.completed = false;
				}
			},
			validateWebsite() {
				let value, validator;
				value = this.data.value;
				validator = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
				if(validator.test(String(value).toLowerCase())) {
		    		this.data.completed = true;
		    	}
				else {
					this.data.completed = false;
				}
			},
			setStatus() {
				switch(this.data.completed) {
					case true:
						return 'complete';
					break;
					case false:
						return 'error';
					break;
					case null:
						return '';
					break;
				}
			},
			checkQuestions() {
				let completed, questions;
				completed = 0;
				questions = this.qstn.sections[this.qstn.pagination.current].questions.length;
				this.qstn.sections[this.qstn.pagination.current].questions.map((question) => {
					if(question.completed) {
						completed += 1;
					}
				});
				this.qstn.sections[this.qstn.pagination.current].completed = false;
				if(completed == questions) {
					this.qstn.sections[this.qstn.pagination.current].completed = true;
				}
			}
		},
		created() {

		},
		mounted() {

		}
	}
</script>