<template>
	<div class="qstn-form qstn-form-my9b86n0">
        <div class="qstn-form-content">
            <div class="qstn-form-title"></div>
            <div class="qstn-form-sections" v-for="(section, index) in qstn.sections" v-show="qstn.pagination.current == index">
            	<div class="qstn-form-field" v-for="question in section.questions">
            		<component :is="question.usage" :qstn="qstn" :data="question"></component>
            	</div>
            </div>
            <div class="qstn-form-btns tmg-wrapper">
            	<div class="qstn-form-back-btn tmg-wrapper" @click="back()" v-if="qstn.pagination.current > 0">
            		<div class="qstn-form-btn-holder tmg-wrapper tmg-25">back</div>
            	</div>
            	<div class="qstn-form-btn tmg-wrapper" @click="submit()" :class="isEnabled()">
            		<div class="qstn-form-btn-holder tmg-wrapper tmg-25">continue</div>
            	</div>
            </div>
        </div>
        <div class="qstn-form-message">thank you for signing up!</div>
    </div>
</template>

<script>
	import qstn_input from "./layout/input.vue";
	import qstn_select from "./layout/select.vue";
	import qstn_textarea from "./layout/textarea.vue";

	export default {
		components: {
			qstn_input,
			qstn_select,
			qstn_textarea
		},
		data() {
			return {
				data: null
			}
		},
		props: ['qstn'],
		methods: {
			init() {
				let pagination = {
					current: 0,
					sections: this.qstn.sections.length
				};
				this.qstn.pagination = pagination;
				this.qstn.sections.map((section, index) => {
					this.qstn.sections[index].completed = false;
					section.questions.map((question) => {
						question.completed = null;
					});
					section.active = false;
					if(index == 0) {
						section.active = true;
					}
				});
			},
			isActive(obj, val) {
				if(obj.pagination.current == val) {
					return true;
				}
				return false;
			},
			checkSection() {
				let current;
				current = this.qstn.pagination.current;
				// this.qstn.section[current].map((section) => {
				// 	console.log(section);
				// });
				this.qstn.sections.map((section, index) => {
					// section.active = false;
					// if(index == (current + 1)) {
					// 	section.active = true;
					// 	this.qstn.pagination.current = index;	
					// }
				});
				// this.checkQuestion();
			},
			back() {
				this.qstn.pagination.current -= 1;
			},
			submit() {
				let isLastPage = (this.qstn.pagination.current + 1) == this.qstn.pagination.sections ? true : false;
				if(isLastPage && this.qstn.sections[this.qstn.pagination.current].completed) {
					this.gatherData();
					this.sendForm();
				}
				else {
					if(this.qstn.sections[this.qstn.pagination.current].completed) {
						this.qstn.pagination.current += 1;
					}
					else {
						this.$swal.fire(
			                'Uh-oh!',
			                'It looks like you haven\'t finished the form.',
			                'warning'
			            );
					}
				}
			},
			gatherData() {
				let data = {};
				this.qstn.sections.map((section) => {
					section.questions.map((question) => {
						data[question.title] = question.value;
					});
				});
				this.data = data;
			},
			resetQuestions() {
				this.qstn.sections.map((section) => {
					section.questions.map((question) => {
						question.value = null;
						question.completed = null;
					});
				});
			},
			sendForm() {
				let base = this;
				axios.post(this.qstn.endpoint, {
					...this.data
				})
				.then((response) => {
					base.resetQuestions();
					if(response.data.hasOwnProperty('status')) {
						this.$swal.fire(
			                'Uh-oh!',
			                response.data.reason,
			                response.data.status
			            );
					}
					else {
						this.$swal.fire(
			                'Success',
			                'Your message was successfully submitted.',
			                'success'
			            );
			            if(base.qstn.redirect) {
			            	setTimeout(() => {
			            		location.href = base.qstn.redirect;
			            	}, 2500);
			            }
					}
				})
				.catch((error) => {
					console.error(error);
				});
			},
			isEnabled() {
				let btnClass = '';
				if(this.qstn.sections[this.qstn.pagination.current].completed) {
					btnClass += 'ready';
				}
				if(this.qstn.pagination.current > 0) {
					btnClass += ' resized';
				}
				return btnClass;
			}
		},
		created() {
			this.init();
		},
		mounted() {

		},
		watch: {
			// 'qstn': {
	  //   		handler: function (val, oldVal) {
	  //               // console.log(val, oldVal);
	  //               this.checkQuestion();
		 //        },
		 //        deep: true
	  //   	}
		}
	}
</script>