<template>
	<div class="tmg-page">
	    <div class="tmg-angled-sections">
	        <div class="tmg-section tmg-angled-section tmg-section-hero tmg-section-type-gradient tmg-section-layout-normal tmg-25" style="background: linear-gradient(-45deg, rgba(255,255,255,0.5), rgba(0,0,0,1)); filter: grayscale(100%); background-size: cover; background-position: center center;">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-angled-section-wrapper tmg-container">
	                <div class="tmg-wrapper">
	                    <div class="tmg-xs-100 tmg-sm-50 tmg-angled-section-title">
	                        <div class="tmg-page-content">
	                            <div class="tmg-hero-title"> We love <br>
									<VueWriter :array="['marketing!','creating!','socializing!']" :caret="cursor" />
	                            </div>
	                            <div class="tmg-hero-text"> For us, there's nothing greater than helping a <span>local business</span> or <span>entrepreneur</span> grow to see their dreams come true. You have the idea, we're just here to help make it as successful as you want it to be. </div>
	                        </div>
	                    </div>
	                    <div class="tmg-xs-100 tmg-sm-50 tmg-angled-section-content">
	                        <div class="tmg-hero-contact">
	                            <div class="tmg-hero-contact-text"> Sign up to receive a <span>free</span> website + social media audit </div>
	                            <div class="tmg-hero-contact-form">
	                                <qstn :qstn="qstn"></qstn>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="tmg-section tmg-angled-section tmg-section-services tmg-section-type-normal tmg-25" style="background: #fff">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-angled-section-wrapper tmg-container">
	                <div class="tmg-wrapper">
	                	<div class="tmg-xs-100 tmg-sm-100 tmg-angled-section-title">
	                        <div class="tmg-page-content tmg-wrapper">
	                            <div class="tmg-page-title">
	                            	Our services
	                            </div>
	                        </div>
	                    </div>
	                    <div class="tmg-xs-100 tmg-sm-100 tmg-angled-section-content">
	                        <sldr v-if="services.items" :sldr="services"></sldr>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="tmg-section tmg-angled-section tmg-section-about tmg-section-type-gradient tmg-section-layout-normal tmg-25" style="background: linear-gradient(225deg, rgba(0,0,0,0.95), rgba(255,255,255,0.5));">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-angled-section-wrapper tmg-container" style="color: #fff;">
	                <div class="tmg-wrapper">
	                    <div class="tmg-xs-100 tmg-sm-100 tmg-angled-section-title">
	                        <div class="tmg-page-content tmg-wrapper">
	                            <div class="tmg-page-title"> Who we are </div>
	                        </div>
	                    </div>
	                    <div class="tmg-xs-100 tmg-sm-70 tmg-angled-section-content">
	                        <div class="tmg-page-content">
	                            <div class="tmg-page-text">A marketer and a developer walk into a coffee shop... what walked out was something bigger than either of us could have imagined. Through our common love for <span>coffee + creativity</span>, we discovered that despite having corporate experience - we were both missing something in our careers and lives: using our powers for good. In other words, <span>helping businesses truly grow.</span> For us, there's nothing greater than helping a local business or entrepreneur grow to see their dreams come true. Reach for the stars! Because in this day and age anything is possible. <br>
	                                <br>
	                                <router-link to="/team">
	                                    <div class="tmg-page-btn tmg-wrapper">Meet the team!</div>
	                                </router-link>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="tmg-section tmg-angled-section tmg-section-blog tmg-section-type-normal tmg-25" style="background: #ffff">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #ffff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #ffff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-angled-section-wrapper tmg-container">
	                <div class="tmg-wrapper">
	                	<div class="tmg-xs-100 tmg-sm-100 tmg-angled-section-title">
	                        <div class="tmg-page-content tmg-wrapper">
	                            <div class="tmg-page-title"> Our Network </div>
	                        </div>
	                    </div>
	                    <div class="tmg-xs-100 tmg-sm-80 tmg-angled-section-content">
	                    	<div class="tmg-partners-container tmg-wrapper">
		                        <div class="tmg-xs-100 tmg-sm-33 tmg-md-25" v-for="partner in partners">
		                        	<div class="tmg-partner tmg-wrapper" style="margin: 15px">
		                        		<img v-lazy="`/partners/`+partner.src" :alt="partner.name" width="80%" />
		                        	</div>
		                        </div>
		                   </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="tmg-section tmg-angled-section tmg-section-contact tmg-section-type-gradient tmg-section-layout-normal tmg-25" style="background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);">
	            <div class="tmg-section-top">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-section-block"></div>
	            <div class="tmg-section-bottom">
	                <svg viewBox="0 0 1440 120" aria-hidden="true">
	                    <path style="fill: #fff;" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
	                </svg>
	            </div>
	            <div class="tmg-angled-section-wrapper tmg-container">
	                <div class="tmg-wrapper">
	                    <div class="tmg-xs-100 tmg-sm-75 tmg-md-60 tmg-lg-50 tmg-angled-section-content">
	                        <div class="tmg-hero-contact">
	                            <div class="tmg-hero-contact-text"> Let's make contact </div>
	                            <div class="tmg-contact-form">
	                                <qstn :qstn="contact"></qstn>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>

	import VueWriter from 'vue-writer';
	export default {
		components: {
			VueWriter
		},
		data() {
			return {
				services: {
					items: [
						{
							title:'Social Media Management',
							image: '',
							content:'Did you know that 90% of all marketers say social media marketing has increased their business exposure? Or that 66% of marketers that spend at least 6 hours on social per week have seen more leads? (Source: Content Factory). In this day and age, properly managing multiple social channels is key to driving business. Whether it’s organic or paid, social media is all about grabbing the attention of your target audience, and driving them to your website or landing page. Alien Marketing will put in extra terrestrial hours to establish your brands social presence,   deliver relevant content to your target audiences, and drive the right traffic to your website or landing page.',
							link:'',
						},
						{
							title:'SEO & SEM',
							image: '',
							content:'Search engine optimization (SEO) allows your business to be discovered by those searching for what you offer. Sounds easy right? Then why do so many companies fail to properly attain this? It’s because SEO is a moving target. Search engines are constantly changing and evolving, making it difficult for businesses to keep up. Which is why you need Alien Marketing’s help - we’ll ensure your website ranks high in search engines and use our intergalactic knowledge to increase the visibility of your site to lead to a higher ranking in search engine results.',
							link:'',
						},
						{
							title:'Web Design & Development',
							image: '',
							content:'If marketing were a baseball game, then your website would be the home base. It’s the one thing that all of your online efforts circle back to. Having a strong website not only boosts credibility but helps you stand out from competitors. We will design and develop a comprehensive website that is both beautiful and functional.',
							link:'',
						},
						{
							title:'Reporting & Analytics',
							image: '',
							content:'We like to think of reporting & analytics as the mothership. It’s the big thing that holds together all the important stuff. And we get it - graphs and numbers can be confusing. Which is why Alien Marketing makes it easy for you to stay up-to-date with your social media and web performance with our Custom Analytics Dashboard to help determine what\'s working and what isn\'t. We offer monthly, weekly, and even daily analytic reports that break down your digital performance with tips on how to optimize.',
							link:'',
						},
						{
							title:'Email & SMS Marketing',
							image: '',
							content:'According to Radicati Group, more than 3.7 billion people worldwide use email. That’s almost 54% of the world’s population! Proving that email is still one of the best ways to keep in touch with your customer. However, there’s a lot of noise out there. So how can you ensure your business stands out from the myriad of other messages hitting your target audience’s inbox? Having the right content, design and cadence can make the world of difference. So why not leave it to the experts? Still not convinced that email marketing is the way to go? Then try SMS marketing. It’s a sure fire way to keep your business top-of-mind by reaching your audience directly on their phones.',
							link:'',
						}
					],
					active_items: null,
					responsive: {
						'xs': {
							items: 1
						},
						'sm': {
							items: 2
						},
						'md': {
							items: 3
						}
					},
					template: 'sldr-cards'
				},
				stories: {
					items: null,
					active_items: null,
					responsive: {
						'xs': {
							items: 1
						}
					},
					template: 'sldr-gallery'
				},
				partners: [
					{
						src: 'mg-incipio-logo.png',
						name: 'incipio'
					},
					{
						src: 'mg-incase-logo.png',
						name: 'incase'
					},
					{
						src: 'mg-cuddly-logo.png',
						name: 'cuddly'
					},
					{
						src: 'mg-livemetta-logo.png',
						name: 'livemetta'
					},
					{
						src: 'mg-frau-logo.png',
						name: 'frau'
					},
					{
						src: 'mg-onirikka-logo.png',
						name: 'onirikka'
					},
					{
						src: 'mg-kanvas-logo.png',
						name: 'kanvas'
					},
					{
						src: 'mg-ozobot-logo.png',
						name: 'ozobot'
					},
					{
						src: 'mg-kavada-logo.png',
						name: 'kavada'
					},
					{
						src: 'mg-educoded-logo.png',
						name: 'educoded'
					},
					{
						src: 'mg-ottolytics-logo.png',
						name: 'ottolytics'
					}
				],
				qstn: {
					endpoint: '/api/submit-audit',
					sections: [
						{
							questions: [
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'name',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'barry allen',
									attr: {
										min: 3,
										max: 36,
										set: 'name'
									}
								},
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'email',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'barry@example.com',
									attr: {
										min: 6,
										max: 90,
										set: 'email'
									}
								},
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'phone',
									value: null,
									required: true,
									paramas: {},
									placeholder: '(949) 999-9999',
									attr: {
										min: 14,
										max: 14,
										set: 'phone'
									}
								},
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'website',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'https://www.maionegroup.com',
									attr: {
										min: 3,
										max: 90,
										set: 'website'
									}
								}
							]
						},
						{
							questions: [
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'company',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'Acme Inc.',
									attr: {
										min: 3,
										max: 36,
										set: 'company'
									}
								},
								{
									type: 'select',
									usage: 'qstn_select',
									title: 'industry',
									value: null,
									required: true,
									paramas: {},
									placeholder: '',
									attr: {
										set: 'industry'
									},
									options: ['aerospace','agribusiness','arms','automotive','broadcasting','chemical','computer','construction','defense','education','electrical power','electronics','energy','entertainment','film','financial services','fishing','food','fruit production','health care','hospitality','information','insurance','internet','manufacturing','mass media','mining','music','news media','petroleum','pharmaceutical','private military company','private security company','public utility','publishing','pulp and paper','real estate','shipbuilding','software','steel','technology','telecommunications','timber','tobacco','transport','water','world wide web']
								},
								{
									type: 'select',
									usage: 'qstn_select',
									title: 'budget',
									value: null,
									required: true,
									paramas: {},
									placeholder: '',
									attr: {
										set: 'budget'
									},
									options: ['0-500','500-1000','1000-2500','2500+']
								}
							]
						}
					]
				},
				contact: {
					endpoint: '/api/submit-contact',
					sections: [
						{
							questions: [
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'name',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'barry allen',
									attr: {
										min: 3,
										max: 36,
										set: 'name'
									}
								},
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'email',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'barry@example.com',
									attr: {
										min: 6,
										max: 90,
										set: 'email'
									}
								},
								{
									type: 'text',
									usage: 'qstn_input',
									title: 'phone',
									value: null,
									required: true,
									paramas: {},
									placeholder: '(949) 999-9999',
									attr: {
										min: 14,
										max: 14,
										set: 'phone'
									}
								},
								{
									type: 'textarea',
									usage: 'qstn_textarea',
									title: 'message',
									value: null,
									required: true,
									paramas: {},
									placeholder: 'What\'s on your mind?',
									attr: {
										min: 12,
										max: 1000,
										set: 'message'
									}
								}
							]
						}
					]
				}
			}
		},
		props: ['user'],
		methods: {
			getStories() {
				if(window.env == 'production') {
					let stories = [], base = this;
					axios.get('https://blog.maionegroup.com/wp-json/wp/v2/posts')
					.then((response) => {
						response.data.map((item) => {
							let story = {
								title: item.title.rendered,
								image: item.jetpack_featured_media_url,
								link: item.link
							}
							stories.push(story);
						});
						base.stories.items = stories;
					})
					.catch((error) => {
						console.error(error);
					});
				}
			}
		},
		created() {
			// this.getStories();
		},
		mounted() {
			
		}
	}

</script>

<style>
	.is-typed {
		font-family: "Monaco";
	}

	.is-typed span.typed {
		color: #fff;
		font-weight: 800;
	}

	.is-typed span.cursor {
		display: inline-block;
		width: 3px;
		background-color: #fff;
		animation: blink 1s infinite;
	}

	.is-typed span.underscore {
		display: inline-flex;
		width: 10px;
		height: 1px;
		align-items:flex-end;
		background-color: #fff;
		animation: blink 1s infinite;
	}

	.is-typed span.cursor.typing {
		animation: none;
	}

	@keyframes blink {
		49% {
			background-color: #fff;
		}
		50% {
			background-color: transparent;
		}
		99% {
			background-color: transparent;
		}
	}

	.tmg-partner img {
		width: 80%;
	}
</style>