import { createStore } from 'vuex';
import user from './modules/user';
import layout from './modules/layout';

const store = createStore({
    modules: {
        user,
        layout
    },
    strict: false
});

export default store;