<template>
	<div class="sldr-container tmg-wrapper">
		<keep-alive>
			<component :is="sldr.template" :sldr="sldr" :pagination="pagination" v-if="sldr.items"></component>
		</keep-alive>
        <div class="sldr-pagination" style="width: 100%">
        	<div class="sldr-pagination-container tmg-wrapper">
        		<div class="sldr-pagination-btn tmg-wrapper" @click="prev()">
        			<img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGRhdGEtbmFtZT0iTGF5ZXIgMiIgaWQ9IkxheWVyXzIiPjxwYXRoIGQ9Ik0yMCwyNWExLDEsMCwwLDEtLjcxLS4yOWwtOC04YTEsMSwwLDAsMSwwLTEuNDJsOC04YTEsMSwwLDEsMSwxLjQyLDEuNDJMMTMuNDEsMTZsNy4zLDcuMjlhMSwxLDAsMCwxLDAsMS40MkExLDEsMCwwLDEsMjAsMjVaIi8+PC9nPjxnIGlkPSJmcmFtZSI+PHJlY3QgY2xhc3M9ImNscy0xIiBoZWlnaHQ9IjMyIiB3aWR0aD0iMzIiLz48L2c+PC9zdmc+" width="20px" height="20px" alt="previous" />
        		</div>
        		<div class="sldr-pagination-btn tmg-wrapper" @click="next()">
        			<img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGRhdGEtbmFtZT0iTGF5ZXIgMiIgaWQ9IkxheWVyXzIiPjxwYXRoIGQ9Ik0xMiwyNWExLDEsMCwwLDEtLjcxLS4yOSwxLDEsMCwwLDEsMC0xLjQyTDE4LjU5LDE2bC03LjMtNy4yOWExLDEsMCwxLDEsMS40Mi0xLjQybDgsOGExLDEsMCwwLDEsMCwxLjQybC04LDhBMSwxLDAsMCwxLDEyLDI1WiIvPjwvZz48ZyBpZD0iZnJhbWUiPjxyZWN0IGNsYXNzPSJjbHMtMSIgaGVpZ2h0PSIzMiIgd2lkdGg9IjMyIi8+PC9nPjwvc3ZnPg==" width="20px" height="20px" alt="next" />
        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	export default {
		data() {
			return {
				pagination: {
					items: null,
					active: null
				},
				loaded: false,
				autoplay: true,
				status: null
			}
		},
		props: ['sldr'],
		methods: {
			init() {
				if(this.sldr.items) {
					this.sldr.items.map((sld) => {
						if(!sld.image) {
							sld.image = 'https://via.placeholder.com/1237x695'
						}
					});
					this.loaded = true;
					this.pagination.items = this.sldr.items.length - 1;
					this.pagination.active = 0;
					this.setOptions();
					this.changeSize();
				}
			},
			setOptions() {
				this.sldr.options = {width: null,size: null,items:null};
				this.checkSizes();
			},
			checkSizes() {
				let size, width = window.innerWidth;
				if(width >= 0 && width <= 767) {
					size = 'xs';
				}
				if(width > 767 && width <= 991) {
					size = 'sm';
				}
				if(width > 991 && width <= 1200) {
					size = 'md';
				}
				if(width > 1200) {
					size = 'lg';
				}
				this.sldr.options.width = width;
				this.sldr.options.size = size;

				if(this.sldr.responsive.hasOwnProperty(size)) {
					this.sldr.options.items = this.sldr.responsive[size].items;
				}
				else {
					this.setOtherSizes();
				}
				this.setActiveSlides();
			},
			setActiveSlides() {
				let active_items = [];
				for (var i = 0; i <= (this.sldr.options.items - 1); i++) {
					active_items.push(this.sldr.items[i]);
				}
				this.sldr.active_items = active_items;
			},
			setOtherSizes() {
				let items, sizes = ['xs','sm','md','lg'];
				for (var i = 0; i < sizes.length; i++) {
					if(!this.sldr.responsive[sizes[i]]) {
						this.sldr.options.items = items;
					}
					else {
						items = this.sldr.responsive[sizes[i]].items;
					}
				}
			},
			changeSize() {
				let base = this;
				window.addEventListener('resize', () => {
					base.checkSizes();
				});
			},
			prev() {
				let start, end, items = [];
				if(this.pagination.active == 0) {
					this.pagination.active = this.pagination.items;
				}
				else {
					this.pagination.active -= 1;
				}

				start = this.pagination.active;
				end = this.pagination.active + this.sldr.options.items;

				this.configSlides(start,end,items);
			},
			next() {
				let start, end, items = [];
				if(this.pagination.active == this.pagination.items) {
					this.pagination.active = 0;
				}
				else {
					this.pagination.active += 1;
				}

				start = this.pagination.active;
				end = this.pagination.active + this.sldr.options.items;

				this.configSlides(start,end,items);
			},
			configSlides(start,end,items) {
				if(end > this.sldr.items.length) {
					end = end - this.sldr.items.length;
				}
				if(start > end) {
					for (var i = start; i < this.sldr.items.length; i++) {
						items.push(this.sldr.items[i]);
					}
					for (var i = 0; i < end; i++) {
						items.push(this.sldr.items[i]);
					}
				}
				else {
					for (var i = start; i < end; i++) {
						items.push(this.sldr.items[i]);
					}
				}
				this.sldr.active_items = items;
			}
		},
		created() {
			this.init();
		},
		mounted() {

		}
	}
</script>

<style>

	.sldr-container {
	    position: relative;
	}

	.sld-item-title {
	    text-align: center;
	    margin-top: 20px;
	}

	.sld-item-title a {
		font-weight: 500;
	    font-size: 22px;
	    color: #000;
	}

	.sldr-pagination {
	    position: absolute;
	}

	.sldr-pagination-btn:nth-child(1) {
		left: -20px;
	}

	.sldr-pagination-btn:nth-child(2) {
		right: -20px;
	}

	.sldr-pagination-btn {
		padding: 10px;
	    position: absolute;
	    background: #fff;
	    text-transform: uppercase;
	    letter-spacing: 4px;
	    text-align: center;
	    border: 1px solid rgba(20,20,20,0.05);
	    border-radius: 5px;
	    cursor: pointer;
	}

	.sldr-pagination-btn img {
		width: 20px;
	}

</style>